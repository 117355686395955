@import './variables';

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.no-wrap-text {
  white-space: nowrap;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-80 {
  gap: 80px;
}

.text-gray {
  color: $c-gray;
}

.flex-1 {
  flex: 1;
}

.flex-50 {
  flex: 0 0 50%;
}

.w-100 {
  width: 100%;
}

.text-bold {
  font-weight: 600;
}

.font-small {
  font-size: 12px;
}

.text-gray {
  color: #808080;
}

.text-24 {
  font-size: 24px;
}

.line-h-150 {
  line-height: 150%;
}

.non-clickable {
  pointer-events: none !important;
}
