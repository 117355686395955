$c-black: rgba(0, 0, 0, 0.87);
$c-black-clear: #000000;
$c-white: #ffffff;
$c-gray-dark: #a6a6a6;
$c-gray-darker: #919191;
$c-gray: #bebebe;
$c-gray-light: #e0e0e0;
$c-blue: #2571ab;
$c-green: #00c853;
$c-red: #e53935;
$c-orange: #fac163;
$c-yellow-light: #fffc9f;

@mixin formFieldInsideTableCell() {
  ::ng-deep {
    .mat-mdc-form-field-infix {
      border-top-width: 0;
      padding: 0 0 0.1rem;
      width: auto !important;
    }
    .mat-mdc-form-field-label {
      display: none;
    }
    .mat-mdc-form-field-underline {
      bottom: 0;
    }
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}
