.lead-overview-tables-common-sidenav-container {
  width: 100%;
  height: calc(100% - 64px);
  margin: 0 29px;

  &.own-tables {
    .sidenav-content + .content {
      max-width: calc(100% - 48px);
    }
  }

  .sidenav-content {
    min-width: 0;
    border-radius: 0;
    padding: 1rem 0;
    padding-top: 0;
    background-color: transparent;
    width: 0;

    & + .content {
      margin-right: 29px;
    }

    &.open {
      max-width: 280px;
      min-width: 280px;

      .filter-content {
        overflow-y: auto !important;
      }

      .dialog-actions.filter {
        display: flex !important;
      }
    }
  }

  & > .sidenav {
    margin-left: 2rem;
  }

  .lead-overview-rotate-btn {
    position: absolute !important;
    top: 16px !important;
    right: -16px !important;
    width: 32px !important;
    height: 32px !important;
    z-index: 10;
    transition: transform 0.5s;

    &.rotated {
      transform: rotateY(180deg);
    }
    .mat-mdc-button-wrapper {
      .material-icons {
        margin-top: -8px;
      }
    }

    &.fixed {
      display: none;

      &:hover {
        display: inline-block;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .mat-mdc-sidenav-content.closed {
      margin-left: 24px;
    }
    .mat-mdc-sidenav.sidenav {
      overflow-y: initial !important;

      &:hover {
        .lead-overview-rotate-btn.fixed {
          display: inline-block;
        }
      }
    }
    .mat-mdc-sidenav.sidenav[style*='visibility: hidden'] {
      visibility: visible !important;
      display: block !important;
      transform: translate3d(calc(-100% + 24px), 0, 0) !important;
    }
  }

  @media screen and (max-width: 959px) {
    .hide-in-sidenav-mobile {
      display: none !important;
    }
  }
}

.lead-overview-tables-common-content-border {
  border-bottom: transparent solid 0;
}

@media screen and (min-width: 960px) {
  .hide-in-sidenav-not-mobile {
    display: none !important;
  }
}
