.print-only {
  display: none;
}
@media print {
  .print-only {
    display: inherit;
  }
}
body.print {
  .td-expand-icon {
    display: none;
  }
}
@media print {
  @page {
    width: 210mm;
    margin: 10mm;
  }
  script {
    display: none;
    visibility: hidden;
  }
  .mat-mdc-checkbox {
    .mat-mdc-checkbox-input {
      display: none;
    }
    .mat-mdc-checkbox-ripple {
      display: none;
    }
    .mat-mdc-checkbox-frame {
      height: 18px;
      width: 18px;
    }
    .mat-mdc-checkbox-background {
      display: block;
      height: 18px;
      width: 18px;
      margin-top: -18px;
      padding-top: 0;
      padding-left: 0;
    }
    .mat-mdc-checkbox-inner-container {
      padding-top: 2px;
    }
  }
  .mat-mdc-radio-button {
    .mat-mdc-radio-input {
      display: none;
    }
    .mat-mdc-radio-ripple {
      display: none;
    }
    .mat-mdc-radio-inner-circle {
      margin-top: -20px;
    }
  }
  /*
  html {
    height: auto;
  }
  body {
    height: auto;
    border: solid 3px red;
  }
  .pc-main {
    overflow: visible;
    height: auto;
    min-height: auto;
    .layout {
      overflow: visible !important;
      height: auto !important;
      min-height: auto !important;
      display: block !important;
      group: static !important;
    }
  }
  .mat-mdc-toolbar,
  .mat-mdc-toolbar.mat-mdc-toolbar-single-row {
    display: none;
  }
  .mat-mdc-drawer-container.mat-mdc-sidenav-container {
    display: block;
    group: static !important;
    overflow: visible;
    height: auto;
  }
  .mat-mdc-drawer-content.mat-mdc-sidenav-content {
    height: auto;
    min-height: auto;
    overflow: visible;
  }
  .project-description {
    group: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 4000px;
    display: block;
    overflow: visible;
  }
  //*/
  // Old definitions
  //*
  *,
  * *,
  * * *,
  * * * * {
    position: static !important;
  }
  html {
    overflow: visible !important;
    display: block !important;
    float: none !important;
    height: auto !important;
  }
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    overflow: visible !important;
    display: block !important;
    float: none !important;
    //height: 4000px;
    > * {
      display: block;
      overflow: visible;
    }
    > * > * > * {
      position: static;
      height: auto;
      display: block;
    }
  }
  .mat-mdc-drawer-content.mat-mdc-sidenav-content {
    overflow: visible !important;
    height: auto !important;
    float: none !important;
    display: block !important;
    > * > * {
      overflow: visible !important;
      height: auto !important;
      display: block !important;
      float: none !important;
    }
  }
  .mat-mdc-drawer-container.mat-mdc-sidenav-container {
    position: static !important;
    background-color: #fff !important;
    overflow: visible !important;
    display: block !important;
    height: auto !important;
    float: none !important;
  }
  body .pc-lead-content-container {
    margin-top: 0 !important;
    display: block !important;
    > * {
      max-width: none;
      width: auto;
    }
  }
  .no-print {
    display: none !important;
  }
  .mat-mdc-toolbar {
    display: none !important;
  }
  .avoid-break {
    page-break-inside: avoid;
  }
  .form-row {
    page-break-inside: avoid;
  }
  .split {
    max-width: 190mm;

    > * {
      flex: 1;
      margin-left: 20px;
      margin-right: 20px;
    }
    > *:first-child {
      margin-left: 0;
    }
    > *:last-child {
      margin-right: 0;
    }
  }

  .project-description {
    display: block;
    max-width: 247mm;
    font-size: 12px;
    height: 4000px;

    //.td-expansion-panel  {
    //  background-color:  whitesmoke;
    //}
    .mat-mdc-input-element {
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
    .mat-mdc-card.fullscreen {
      .mat-mdc-card-content {
        overflow-y: visible !important;
        max-height: none !important;
      }
    }
    label {
      font-size: 12px;
    }
    .form-row {
      > * {
        align-self: flex-end;
      }
      > label {
        align-self: baseline;
        font-size: 12px;
        min-width: 120px;
        max-width: 120px;
      }
    }
    .split {
      flex-wrap: nowrap; //flex-direction: column;
    }
    .form-row {
      margin-bottom: 20px;
      min-height: 50px;
    }
    button {
      display: none;
    }
    > .mat-mdc-card {
      border: 0 !important;
      margin: 0 !important;
      box-shadow: none !important;
      .mat-mdc-card-title {
        font-size: 18px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .mat-mdc-card-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .mat-mdc-divider {
      display: none;
    }
    .mat-mdc-padding {
      padding: 0 !important;
    }
    .mat-mdc-expansion-panel {
      box-shadow: none !important;
    }
    .mat-mdc-icon {
      display: none;
    }
    input[type='text'],
    textarea {
      margin-top: 10px;
      border-bottom: solid 1px #ccc;
    }
    .mat-mdc-form-field--no-underline {
      input[type='text'] {
        border-bottom: 0;
        margin-top: inherit;
      }
    }

    .printTitle {
      font-size: 34px;
      font-weight: 700;
      margin-bottom: -15px;
    }

    .mat-mdc-expansion-panel-header-title,
    .td-expansion-label {
      padding: 5px;
      background-color: lighten(#808080, 20%);
      border-radius: 5px;
    }

    .verticalLine {
      border-right: 1px solid gray !important;
      padding-right: 40px;
      min-width: 350px;
      display: block;
    }
  }
  .mat-mdc-input-underline {
    display: none !important;
  }

  .mat-mdc-tab-labels {
    display: none !important;
  }

  .btn-new-note-variant {
    display: none !important;
  }

  .menu-variant-note-list {
    display: none !important;
  }

  .max-lines {
    max-height: none !important;
  }

  .lead-location-map {
    max-width: none !important;
  }

  .pc-lead-note-container-print {
    max-width: 190mm !important;

    .mat-mdc-card {
      box-shadow: none !important;
    }

    .print-button-lead-note-list {
      display: none !important;
    }
  }

  //*/
}
@media print and (max-width: 650px) {
  .form-row {
    flex-direction: row;
    label {
      margin-bottom: 0;
    }
  }
}
