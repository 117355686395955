@use '@angular/material' as mat;
@import './variables';
// Plus imports for other components in your app.

// Define a custom typography config that overrides the font-family
// or any typography level.
$typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $body-1:
    mat.define-typography-level(
      $font-size: 14px,
    ),
  $body-2:
    mat.define-typography-level(
      $font-size: 14px,
    ),
);

$pc-accent: (
  100: $c-black,
  500: $c-black,
  700: $c-black,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$pc-blue: (
  100: $c-blue,
  500: $c-blue,
  700: $c-blue,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$pc-red: (
  100: $c-red,
  500: $c-red,
  700: $c-red,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat.define-palette($pc-blue);
$accent: mat.define-palette($pc-accent);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette($pc-red);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography: $typography,
    density: 0,
  )
);

@include mat.core();
@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($theme);

// Active icon color in list nav
mat-nav-list {
  [mat-list-item].active {
    mat-icon[mat-list-avatar] {
      background-color: mat.get-color-from-palette($accent);
      color: mat.get-color-from-palette($accent, default-contrast);
    }
    mat-icon[mat-list-icon] {
      color: mat.get-color-from-palette($accent);
    }
  }
}

/* ------------------------------------------------------------------------------- */
$foreground: map-get($theme, foreground);
$background: map-get($theme, background);

// Apply theme for this app

// NGX Charts
[ngx-charts-axis-label] text {
  fill: mat.get-color-from-palette($foreground, secondary-text);
}
.tick text {
  fill: mat.get-color-from-palette($foreground, disabled);
}
.gridline-path {
  &.gridline-path-horizontal,
  &.gridline-path-vertical {
    stroke: rgba(black, 0.06);
  }
}
.legend-title-text {
  color: mat.get-color-from-palette($foreground, secondary-text);
}
ngx-charts-line-chart,
ngx-charts-area-chart,
ngx-charts-area-chart-stacked {
  .gridline-path {
    &.gridline-path-vertical {
      display: none;
    }
  }
}
ngx-charts-line-chart {
  .line-series {
    .line {
      stroke-width: 2;
    }
  }
}
