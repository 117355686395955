.margin-top-0 {
  margin-top: 0;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-36 {
  margin-bottom: 36px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-64 {
  margin-top: 64px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-40 {
  margin-right: 40px;
}

.padding-16 {
  padding: 16px;
}

.padding-20 {
  padding: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-40 {
  padding-top: 40px;
}

.margin-icon-header {
  margin-right: 6px;
  margin-bottom: 6px;
}

.m-16 {
  margin: 16px;
}

.padding-8 {
  padding: 8px;
}
