$filter-actions-height: 270px;
$filter-padding: 0px;
$mat-sidenav-content-padding-right: 15px;
$filter-padding-right: calc(24px - #{$mat-sidenav-content-padding-right});

.filter-content {
  max-width: 280px;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: $filter-padding;
  gap: 2rem;
  display: flex;
  flex-direction: column;

  .filter-content-block {
    background: #ffffff;
    padding: 20px;
    gap: 1rem;
    margin-bottom: 0px;
    border-radius: 16px;
    min-width: 200px;
  }
}

.dialog-actions.filter {
  margin-top: 20px;
  margin-right: 20px;
  display: none !important;
}
