.button-toggle-group {
  border: 0 !important;
  display: flex;
  flex-wrap: wrap;

  .button-toggle {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    min-width: 40px;
    margin: 0 8px 4px 0;
  }

  .mat-mdc-button-focus-overlay {
    background-color: transparent !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: transparent 1px;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 18px !important;
    padding-top: 3px !important;
    padding-bottom: 3px;
  }
}
