@use '@sitewerk/theia-ui-lib/styles/variables/spacing' as spacing;
.contact-form {
  &__field {
    flex: 1;
    width: 100%;
    max-width: 630px;
    display: block;
  }

  &__contact-info {
    max-width: 630px;
    min-width: 120px;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 960px) {
    &__block:nth-child(2n + 1) {
      margin-right: spacing.$S9;
    }
  }

  &__image-title {
    margin-top: 0;
  }

  &__btn-delete {
    opacity: 0;
    pointer-events: none;
  }

  &__image:hover {
    .contact-form__btn-delete {
      opacity: 1;
      pointer-events: initial;
    }
  }

  .contact-form-content-wrapper {
    margin: 2rem 0;
  }

  .mat-mdc-card-image {
    width: 100%;
    margin: 0;
  }
}

// Create contact autocomplete
.contact-form-autocomplete-options-wrapper {
  overflow: hidden !important;
  max-height: 520px !important;

  .contact-form-autocomplete-options {
    max-height: 260px;
    overflow-y: auto !important;
  }

  .contact-form-autocomplete-options-empty {
    max-height: 0;
    overflow-y: auto !important;
  }

  .contact-form-autocomplete-options-footer {
    padding: 16px;

    .contact-form-button {
      padding: 0 16px;

      span {
        text-transform: none;
      }
    }
  }
}
