.tab-group-content-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 350px);
  overflow-y: auto;
}

@media all and (min-width: 1367px) {
  .tab-group-content-wrapper {
    height: calc(100vh - 250px);
  }
}
