@use '@sitewerk/theia-ui-lib/styles/variables/colors' as colors;

$ngx-datatable-row-odd-background: #eee;
$ngx-datatable-selected-active-background-hover: #eee;
$ngx-datatable-selected-active-background-focus: #eee;
$ngx-datatable-selected-active-background: #eee;
$ngx-datatable-default-background-hover: #eee;
$ngx-datatable-default-background-focus: #eee;
@import 'ngx-datatable/index.scss';
@import 'ngx-datatable/material.scss';
@import 'ngx-datatable/icons.css';
@import 'lightbox2/dist/css/lightbox.min.css';
@import 'dragula/dist/dragula.css';
@import 'theme';
@import './dd-article-tree.scss';
@import './print.scss';
@import 'theia-pv-planning-module/assets/theia-pv-planning-module/styles/bundled.scss';
@import 'lead-overview-filter.scss';
@import 'lead-address.scss';
@import 'lead-overview-tables-common.scss';
@import 'margin-padding.scss';
@import 'util.scss';
@import 'tags-button-toggle-group.scss';
@import 'variant';
@import './mt/mt-crm';

@import 'quill/dist/quill.snow.css';

@import '@sitewerk/theia-ui-lib/styles/index.css';

//https://stackoverflow.com/questions/38623716/how-to-add-custom-font-sizes-to-quilljs-editor
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-size {
  width: 66px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 240px;
  overflow-y: scroll;
}

// for a draggable row
.datatable-row-group {
  display: flex;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.mat-mdc-18 {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.versionInfoToolTip {
  white-space: pre-line;
}

// Logo
.mat-mdc-icon.mat-mdc-icon-logo {
  height: 60px;
  width: 120px;
  margin-left: 5px;
}

pc-lead-task-new-dialog {
  .date-time-picker {
    .owl-datetime-main-input {
      height: 40px;
    }
  }
}

pc-accordion-wizard-step {
  display: block;
  margin: 0 auto 10px;
  width: 820px;
}

pc-lead-overview,
pc-mt-lead-overview,
pc-bom-edit,
pc-create-variant {
  height: 100%;
  overflow: hidden;
}

.mat-mdc-progress-spinner {
  &,
  svg {
    width: 45px !important;
    height: 45px !important;
  }
}

mat-tree {
  ul,
  li {
    list-style-type: none;
    margin: 0;
  }

  ul {
    padding-left: 1em;
  }

  .mat-mdc-tree-node {
    font-family: 'Inter', sans-serif;
    align-items: end;
    min-height: 20px;

    &:not(li) {
      margin-bottom: 8px;
    }
  }

  .mat-mdc-icon-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-right: 4px;

    .mat-mdc-icon {
      text-overflow: clip !important;
      overflow: hidden;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 20px;
    }

    .add-article-icon {
      width: 0.9em;
      text-overflow: clip !important;
      vertical-align: baseline;
    }
  }
}

.action-buttons-to-right {
  margin: 30px 0 20px;
  text-align: right;
}

//DateTimePicker
.pc-dateTimePicker .mat-mdc-form-field-underline {
  display: none;
}

.mat-mdc-datepicker-dialog .mat-mdc-dialog-container {
  min-width: 300px !important;
}

mat-month-view {
  .mat-mdc-calendar-body-cell {
    position: absolute !important;
  }
}

ngx-mat-datetime-content {
  .mat-mdc-calendar-body-cell {
    position: relative !important;
  }

  .mat-mdc-calendar-arrow {
    width: 0 !important;
  }
}

// dialog
.dialog {
  height: auto;
  width: fit-content;
  min-width: 470px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;

  @media screen and (max-width: 599px) {
    min-width: calc(100% - 36px) !important;
  }

  .mat-mdc-dialog-content {
    max-height: calc(90vh - 300px);
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end !important;
  }
}

// Mat-expansion-panel
.mat-mdc-expansion-panel-flat {
  box-shadow: none !important;

  .panel-content {
    padding-top: 20px;
  }
}

.mat-mdc-expansion-panel-empty-header {
  height: 2rem !important;
  justify-content: center;

  .mat-mdc-content {
    flex: 0;
  }
}

.mat-mdc-expansion-panel-header {
  .mat-mdc-expansion-panel-header-title {
    font-size: 1rem;
    font-weight: bold;
  }
}

.attachment-list {
  font-size: 1rem;
  min-height: 50px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;

  .mat-mdc-tree-node {
    margin-left: -7px;

    .mat-mdc-tree-node-content {
      padding-right: 10px;
      display: flex;
      flex-direction: row;

      button {
        width: 18px;
      }

      .mat-mdc-checkbox-layout {
        .mat-mdc-checkbox-inner-container {
          margin-top: 3px;
        }

        .mat-mdc-checkbox-label {
          max-width: 330px;
          word-wrap: normal;
          white-space: pre-wrap;
        }
      }
    }
  }
}

html .mat-mdc-card {
  padding: 0;

  .mat-mdc-card-content {
    padding: 16px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .mat-mdc-card-title {
    padding: 16px 16px 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    min-height: 40px;
    box-sizing: border-box;
  }
}

mat-sidenav-content {
  background-color: #e0e0e0 !important;
}

.mat-mdc-drawer-inner-container {
  mat-sidenav-content {
    background-color: #ffffff !important;
  }
}

// Needs to be set since newest angular material otherwise every divider breaks layout
.mat-mdc-card .mat-mdc-divider {
  position: relative !important;
}

.mat-mdc-card .mat-mdc-expansion-panel,
.mat-mdc-dialog-container .mat-mdc-expansion-panel {
  box-shadow: none !important;
}

.action-green {
  color: $c-green !important;
  background-color: white !important;
}

.action-red {
  color: $c-red !important;
  background-color: white !important;
}

.action-accent {
  color: $c-gray !important;
  background-color: white !important;
}

.action-primary {
  color: $c-black !important;
  background-color: #fff !important;
}

.snackbar-alert {
  background-color: $c-red !important;
}

.snackbar-primary {
  background-color: $c-blue !important;
}

.mat-mdc-mini-fab {
  box-shadow:
    0 2px 3px -1px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
}

// Custom style for loading elements without height
.will-load {
  min-height: 80px;
}

// mat-select
.mat-mdc-select-disabled .mat-mdc-select-value,
.mat-mdc-select-arrow,
.mat-mdc-select-trigger {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mat-mdc-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

// Capitalize
.text-capital {
  text-transform: capitalize;
}

button {
  text-transform: uppercase;

  &.mat-mdc-button-base:not(.mat-mdc-mini-fab):not(.mat-mdc-icon-button) {
    padding: 8px 12px;
  }
}

.mat-button-toggle-group {
  cursor: default !important;
}

// burger
.burger-button {
  background-color: transparent;
  border: none;
  cursor: default;
  display: block;
  user-select: none;
  height: 30px;
  width: 32px;
  margin-top: -3px;
}

.burger-line {
  display: block;
  width: 24px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 4px 0;
}

.child-row-select {
  cursor: default;
  user-select: none;
  width: 18px;
  height: 30px;
  margin-left: 8px;

  .select-line {
    width: 18px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.text-align-right {
  text-align: right;
}

//form
.form-1000 {
  max-width: 1000px;

  .mat-mdc-input-container {
    margin-bottom: 20px;
  }

  .mat-mdc-checkbox {
    margin: 20px 0 30px;
  }

  .mat-button-toggle-group {
    margin: 0 0 20px;
  }

  .step-sublabel {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.form-custom {
  .mat-mdc-input-container {
    margin-bottom: 20px;
  }

  .mat-mdc-checkbox {
    margin: 20px 0 30px;
  }

  .mat-button-toggle-group {
    margin: 0 0 20px;
  }

  .step-sublabel {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

mat-card {
  margin: 10px !important;
}

.margin-0 {
  margin: 0 !important;
}

.truncate-ellipsis {
  margin-left: 5px;
  margin-right: 5px;
  flex: 1;

  &,
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.flex-center {
  justify-content: center;
}

.pc-dialog-button {
  margin-top: 30px;
}

//main toolbars and layout
pc-lead-main {
  .mat-mdc-toolbar {
    z-index: 4;
  }
}

pc-mt-bom-action-bar {
  .mat-mdc-toolbar {
    z-index: 3;
    position: fixed;
  }
}

.pc-lead-content {
  margin-top: 20px;
}

pc-lead-overview {
  z-index: 1;
}

helion-ui-drawer .navbar {
  padding: 0.75rem 1.5rem;
}

.lead-main-content-wrapper:has(pc-read-only-label) {
  .lead-action-bar {
    top: 30px;
  }

  .pc-lead-notes-container {
    // subtract pc-read-only-label height from max-height
    height: calc(100vh - (72px + 62px + 30px));
  }
}

.lead-action-bar {
  top: 0;
  left: 0;
  position: sticky;
  width: 100%;
  z-index: 30;
}

mat-radio-button {
  margin-bottom: 5px;
}

.datatable-in-expansion-panel {
  padding: 24px 0 0;

  .mat-mdc-expansion-panel-header {
    height: 30px !important;
    padding: 0;

    .mat-mdc-expansion-panel-header-title {
      font-size: 20px;
    }
  }

  .mat-mdc-expansion-panel-body {
    padding: 24px 0 0;
  }
}

//end main toolbars and layout
//ngx-datatable

.ngx-datatable.material .datatable-body-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.material .datatable-row-wrapper:last-child .datatable-body-row {
  border-bottom: none;
}

.ngx-datatable.material .datatable-header-cell-label,
.ngx-datatable.material .datatable-footer .page-count {
  font-size: 12px;
  color: $c-gray;
}

.ngx-datatable.material .datatable-footer .datatable-pager {
  font-size: 12px;
  color: $c-black;
}

.ngx-datatable.material .datatable-footer .datatable-pager .disabled {
  color: $c-gray;
}

.ngx-datatable-small-font {
  font-size: 12px !important;
}

.hidden-object-address {
  visibility: hidden;
}

.hidden {
  display: none;
}

.ngx-datatable.material {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.table-actions {
  cursor: pointer;
}

//
//.owl-dt-container-buttons :nth-child(2) {
//  display: none !important;
//}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  color: $c-gray;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 4px;

  &:first-child {
    padding-left: 10px;
  }
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 10px 4px;

  &:first-child:not(.default-padding) {
    padding-left: 10px;
  }
}

.ngx-datatable-align-content-vertical-center {
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    display: flex;
    align-items: center;
    /* align vertical */
  }
}

.ngx-datatable.material .datatable-footer .page-count {
  line-height: 40px;
  height: 40px;
}

.lead-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - (80px + 88px + 40px));

  helion-ui-accordion {
    margin-bottom: 0 !important;
  }
}

.datatable-align-center {
  text-align: center !important;
  justify-content: center !important;
}

.align-center {
  text-align: center !important;
}

.min-width-60 {
  min-width: 60px !important;
}

.min-width-80 {
  min-width: 80px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.min-width-130 {
  min-width: 130px !important;
}

.min-width-180 {
  min-width: 180px !important;
}

.width-100 {
  width: 100%;
}

.width-95 {
  width: 95%;
}

.width-80 {
  width: 80%;
}

.no-padding-on-cell {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  overflow: hidden;
}

//end ngx-datatable
//SideNav
mat-sidenav,
mat-sidenav.mat-mdc-locked-open-add-active,
mat-sidenav.mat-mdc-closed.mat-mdc-locked-open-add-active,
mat-sidenav.mat-mdc-locked-open {
  width: 290px;
  min-width: 290px;
  max-width: 290px;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  mat-sidenav-content {
    padding-right: 15px;

    mat-form-field {
      display: block !important;
      max-width: 230px;
    }
  }
}

//end SideNav
// Star rating
.star.medium,
.rating.medium .star {
  width: 18px;
  height: 18px;
}

.rating {
  margin-bottom: 0;
}

//end Star rating
//pc lists
.pc-list-item {
  padding: 10px 0;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  helion-ui-select .select input {
    width: 100%;
  }
}

.pc-slide-toggle {
  padding-left: 20px;
  padding-right: 20px;

  span {
    font-size: 12pt;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/* TODO TOBI: check; probably obsolete with new ckeditor */
.pc-note-item {
  padding: 10px 0;

  * {
    line-height: initial !important;
  }

  p,
  ul {
    margin: 0;
  }

  span:nth-child(1) {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.pc-note-item-created {
  color: $c-gray;
  font-size: 12px;
}

mat-list-item:nth-child(1) > div > div.mat-mdc-list-item.pc-note-item,
mat-list-item:nth-child(1) > div > div.mat-mdc-list-item.pc-list-item {
  padding-top: 0;
}

//end pc lists
// RESPONSIVENESS smaller than 1281px
@media (max-width: 1280px) {
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 12px;
  }
  .star.medium,
  .rating.medium .star {
    width: 14px;
    height: 14px;
  }
}

// triage assignment dialog
.triage-ass-dialog {
  .dialog-content {
    .mat-mdc-tab-body-content {
      width: 640px;
      min-height: 250px;
      max-height: 440px;
      height: auto;
      padding: 20px 0;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .mat-mdc-radio-container {
      top: 5px;
    }

    pc-message {
      margin-bottom: 20px;
    }
  }
}

.support-ass-dialog {
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      margin-bottom: 16px !important;
    }

    .mat-mdc-tab-body {
      overflow: hidden !important;

      .mat-mdc-tab-body-content {
        padding: 0 0 20px;
        overflow: hidden !important;
      }
    }

    .mat-mdc-radio-label {
      align-items: baseline;
    }

    .mat-mdc-radio-container {
      top: 5px;
    }
  }

  pc-message {
    margin-bottom: 20px;
  }
}

// mandant selector
pc-mandant-selector {
  min-height: 1px;
  min-width: 250px !important;
  max-width: 250px !important;
  font-size: 16px !important;

  @media screen and (max-width: 599px) {
    min-width: 200px !important;
    margin-right: 0;
  }

  .mat-mdc-select {
    width: 230px;
  }

  .mat-mdc-select-trigger {
    height: 33px;
    margin-left: 20px;
    border-bottom: solid 1px #ffffff !important;

    .mat-mdc-select-value {
      color: #ffffff !important;
    }

    .mat-mdc-select-arrow {
      color: #ffffff !important;
    }

    .mat-mdc-select-underline {
      background-color: #ffffff !important;
    }
  }
}

.siblings-notification-menu.mat-mdc-menu-panel {
  min-width: 730px !important;

  .mat-mdc-menu-content {
    .active-siblings-group {
      margin-left: 10px;

      .active-siblings-group-button {
        font-size: 14px;

        .mat-button-toggle-label-content {
          padding: 5px 10px 5px 10px;
          line-height: 15px;
          color: black;
        }
      }
    }
  }
}

.action-bar.mat-mdc-toolbar {
  .main-action-button {
    min-width: 120px;
  }
}

// tasks dropdown
.task-menu.mat-mdc-menu-panel {
  width: 400px !important;
  max-height: 300px;

  p {
    margin-left: 15px;
  }

  h4 {
    margin-left: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 40px;
  }
}

.mat-mdc-mdc-form-field-infix {
  width: 245px !important;
}

.sap-export-meier-tobler {
  .mat-mdc-mdc-form-field-infix {
    width: 220px !important;
  }
}

.lead-address-additional,
.lead-person-additional {
  .mat-mdc-mdc-form-field-infix {
    width: 100% !important;
  }
}

.mat-mdc-mdc-form-field-underline {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

// Tables lead overview common
.lead-overview-filter-buttons {
  margin-top: 20px;
}

/**
Helper class dd-tab-row which let's you create table row like structures with flex

Example:
<div class='dd-tab-row'>
  <div>Column 1</div>
  <div>Column2</div>
</div>

You can give each column a specific class name and then add constraints specific to that column (max width, flex etc.)
*/
.dd-tab-row {
  overflow-x: auto;
  display: flex;

  > * {
    flex: 1;
  }
}

.honor-line-break {
  white-space: pre-line;
}

.mat-mdc-menu-panel {
  max-width: 350px !important;
  padding: 16px;
  min-height: 32px !important;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;

    .mat-mdc-menu-item {
      height: 38px;
      line-height: 32px;
      padding: 0 5px;
      text-transform: none !important;
      display: flex;
      flex-flow: row;
      align-items: center;

      button {
        padding: 1px;
      }

      .mat-mdc-icon {
        margin-right: 10px;
      }

      .mat-mdc-icon-no-color,
      span {
        color: $c-black !important;
      }
    }

    .mat-mdc-menu-item[disabled] {
      opacity: 0.5;
    }
  }
}

/** Pdf Viewer Customization */
.pdfViewer.removePageBorders {
  height: 100vh;

  .page {
    border: solid 1px #dcdcdc !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

/** Buttton that is displayed below the wizard steps */
.go-to-positions {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.project-description {
  .logo {
    /** Is only visible in print mode */
    width: 150px;
    height: 37px;
  }

  display: block;

  .mat-mdc-checkbox-inner-container {
    margin-top: 0;
  }

  .mat-mdc-checkbox-label {
    white-space: pre-wrap;
  }

  .mat-mdc-padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mat-mdc-radio-button {
    display: block;
    margin-bottom: 3px;
  }

  .contact-info p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mat-mdc-card.fullscreen {
    .mat-mdc-card-content {
      overflow-y: auto;
      max-height: calc(100vh - 280px);
    }
  }

  .mat-mdc-card-content > .avoid-break {
    margin-bottom: 16px;
  }
}

.form-row {
  //border: solid 1px #0f0;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

  > label {
    flex: 0;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    margin-right: 20px;
    font-weight: 600;
  }

  > div > .mat-mdc-input-container {
    width: 100%;
  }

  > div,
  .mat-mdc-input-container {
    flex: 1;
    margin-top: -3px;
  }

  .mat-mdc-input-infix {
    border: 0;
  }
}

.split {
  //border: solid 1px #f0f;
  display: flex;
  flex-wrap: wrap;
  align-content: normal;
  align-items: baseline;

  > * {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
  }

  /*
  > *:first-child {
    margin-left: 0px;
  }
  > *:last-child {
    margin-right: 0px;
  }
  */
}

@media only screen and (max-width: 650px) {
  .form-row {
    flex-direction: column;

    label {
      margin-bottom: 10px;
    }
  }
  .split {
    //flex-direction: column;
    > * {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.mat-mdc-form-field--no-underline .mat-mdc-input-underline {
  background-color: transparent !important;
}

a.no-underline {
  text-decoration: none;
  color: $c-black;
}

.network-trouble-dialog > * {
  .td-dialog-actions {
    visibility: hidden !important;
  }
}

// File upload per drag and drop
.mat-mdc-card-content.full-height {
  height: 100%;

  > * {
    height: 100%;
  }

  .td-loading-wrapper {
    height: 100%;
  }
}

.file-drop {
  height: calc(100% - 40px);

  .drop-zone-visuals {
    display: none;
  }
}

.drop-zone-over {
  background-color: rgba(0, 0, 0, 0.03);
}

.drop-zone {
  * {
    pointer-events: none !important;
  }

  .drop-zone-visuals {
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: dashed 3px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      display: inline-block;
    }
  }

  .not-drop-zone {
    display: none;
  }
}

.mat-button-toggle-disabled.cdk-focused,
.mat-button-toggle-disabled.cdk-mouse-focused {
  outline: none;
}

.flat-row-language-selector {
  .mat-button-toggle-focus-overlay,
  .mat-mdc-ripple-element,
  .mat-mdc-button-focus-overlay {
    background-color: transparent !important;
  }
}

.pictures .mat-mdc-card-content {
  height: 200px;
  min-height: 200px;

  .file-drop {
    height: calc(100% - 120px) !important;
  }
}

/** Variant Properties **/
.properties-row {
  display: flex;

  > * {
    padding-top: 5px;
    flex: 1;
  }
}

.property {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > * {
    padding-top: 10px;
  }

  .mat-mdc-input-infix {
    border-top: 0;
    padding-top: 0;
  }

  .property-icon {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    padding-top: 5px;
  }

  .commit-property {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    padding-top: 0;
    text-align: center;
  }

  .value {
    flex: 1;
  }
}

.invalidProperty {
  * {
    color: $c-red !important;
  }
}

.validation-message {
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  color: $c-red;
}

.justify-content-center {
  justify-content: center;
}

/** Tarifs Dialog **/
.tarifs-dialog {
  display: block;
  min-width: 450px;

  .container {
    width: 100%;
    padding-bottom: 20px;
  }

  .factor {
    text-align: center;
  }
}

/** Wrap text **/
.wrap-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/* Helper classes */
.cursor-pointer {
  cursor: pointer;
}

.text-blue {
  color: $c-blue;

  & svg {
    color: $c-blue !important;
  }
}

.text-green {
  color: $c-green;

  & svg {
    color: $c-green !important;
  }
}

.text-yellow {
  color: $c-yellow-light;
}

.text-orange {
  color: $c-orange;
}

.text-warning {
  color: $c-red;

  & svg {
    color: $c-red !important;
  }
}

.text-transparent {
  color: transparent;
}

.font-italic {
  font-style: italic;
}

.text-strikethrough {
  text-decoration: line-through;
}

.with-font-bold {
  font-weight: bold;
}

.new-person-button {
  min-width: 80px !important;
}

@media all and (max-width: 959px) {
  .mat-mdc-icon.mat-mdc-icon-logo,
  .header-notifications,
  p.user-email,
  i.user-icon,
  .header-app-name {
    display: none;
  }

  .form-custom {
    font-size: 14px !important;
  }

  .hide-mobile {
    display: none !important;
  }

  .new-person-dialog {
    width: 260px !important;
  }

  .new-person-button {
    padding: 0 !important;
    font-size: 12px !important;
  }

  .action-bar.mat-mdc-toolbar {
    .main-action-button {
      min-width: 30px;

      .mat-mdc-button-wrapper {
        padding: 0 10px;
      }
    }
  }

  .pc-lead-content {
    margin-top: 20px !important;
  }

  .grow-mobile {
    width: 90% !important;
    margin-left: 10px;
  }

  .grow-mobile-small {
    width: 50% !important;
    margin-left: 10px;
  }

  .lead-detail-cards {
    height: 100%;
  }

  .lead-detail-content-card {
    .table-actions-mobile {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .variant-list {
    max-height: 180px !important;
  }

  .mat-mdc-toolbar {
    height: 40px !important;

    &.mat-mdc-toolbar-multiple-rows {
      height: auto !important;
    }

    .button-header-round-mobile {
      height: 28px !important;
      width: 28px !important;
    }

    .button-header-round-image-mobile {
      height: 18px !important;
      margin-top: -16px !important;
      font-size: 18px;
    }

    .button-header-round-image-mobile-font-awesome {
      height: 18px !important;
      margin-top: -16px !important;
      font-size: 20px;
    }

    .mat-mdc-raised-button {
      height: 30px !important;

      .mat-mdc-button-wrapper {
        margin-top: -3px !important;
        display: block;
      }
    }
  }

  .date-time-picker {
    margin-left: 0;
  }

  .owl-dt-container-inner {
    margin-right: auto;
    margin-top: 20px;
  }

  .owl-dt-popup {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .owl-dt-popup-container {
    height: fit-content;
  }

  pc-mandant-selector {
    .mobile-mandator-selector {
      height: 1px;
      width: 200px;
    }

    min-width: 0;
    max-width: none;
    margin-right: -25px;
  }

  .overview-toolbar-mobile {
    .header-text-mobile {
      display: none !important;
    }

    .mat-mdc-button-wrapper {
      font-size: 18px;
    }

    .mat-mdc-button {
      padding: 0;
    }

    .tab-button {
      margin-left: 0;
      margin-right: 0;
    }

    .lead-overview-new-button {
      .mat-mdc-button-wrapper {
        font-size: 14px !important;
      }
    }
  }

  .pc-lead-content-container {
    .mat-mdc-card {
      padding-top: 0 !important;

      .mat-mdc-tab-label {
        padding: 5px !important;
      }
    }
  }

  .lead-detail-content-card {
    margin-top: 45px !important;

    .mat-mdc-card {
      margin: 4px !important;
    }

    .lead-detail-cards-half-bigger {
      height: 312px !important;
    }
  }

  .mobile-all-table-cell {
    width: 30% !important;
  }

  .ngx-datatable.material .datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 16px;
    padding: 0 2px;
    border-radius: 3px;
    margin: 2px;
  }

  .datatable-header {
    height: 10px !important;
  }
}

.connection-switcher-wrapper {
  .mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled) .mat-mdc-slide-toggle-bar {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled) .mat-mdc-slide-toggle-thumb {
    background-color: #ffffff;
  }
}

// positions

.position-relative {
  position: relative;
}

.pull-right {
  margin-left: auto;
}

::ng-deep .bom-title-tooltip {
  font-size: 14px !important;
}

@media screen and (max-width: 1024px) {
  .hide-on-less-1024 {
    display: none !important;
  }

  pc-accordion-wizard-step {
    width: 726px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .hide-in-md-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1650px) {
  .hide-medium-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 1440px) {
  .hide-small-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 1366px) {
  .hide-ipad-pro-full {
    display: none !important;
  }

  .ipad-pro-full-width {
    max-width: 100% !important;
  }
}

@media all and (min-width: 1400px) {
  .article-tree .node-content-wrapper span,
  .article-tree .node-content-wrapper a {
    max-width: 450px !important;
    text-overflow: clip !important;
  }

  .article-tree .node-content-wrapper .node-children-purged {
    color: darkgrey !important;
  }
}

@media all and (min-width: 1800px) {
  .article-tree .node-content-wrapper span,
  .article-tree .node-content-wrapper a {
    max-width: 600px !important;
    text-overflow: clip !important;
  }

  .article-tree .node-content-wrapper .node-children-purged {
    color: darkgrey !important;
  }
}

app-solar-planner {
  div.planner {
    height: calc(100vh - 350px) !important;
  }
}

app-solar-planner-google {
  div.planner {
    height: calc(100vh - 350px) !important;
  }
}

.mat-mdc-button,
.mat-mdc-raised-button {
  min-width: 64pt;
}

.mat-mdc-tab-label,
.mat-mdc-tab-link {
  opacity: 1 !important;
  color: $c-gray !important;
}

.mat-mdc-tab-label:focus:not(.mat-mdc-tab-disabled),
.mat-mdc-tab-label.mat-mdc-tab-label-active:not(.mat-mdc-tab-disabled),
.mat-mdc-tab-link:focus:not(.mat-mdc-tab-disabled),
.mat-mdc-tab-link.mat-mdc-tab-label-active:not(.mat-mdc-tab-disabled) {
  color: $c-black !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  &:not(.mat-mdc-chip-disabled) {
    .mat-mdc-chip-remove {
      font-size: 16px;
      width: 16px;
      height: 16px;
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.mat-mdc-input-element {
  max-width: 650px;

  .mat-mdc-form-field-label {
    color: $c-gray !important;
  }
}

.mat-mdc-snack-bar-container {
  margin: 24px 10px !important;
  width: 920px !important;
  max-width: 100% !important;
}

.max-width-100 {
  max-width: 100%;
}

.image-canvas {
  display: block;
  width: 100%;
  height: auto;
}

@media all and (min-width: 1367px) {
  app-solar-planner {
    div.planner {
      height: calc(100vh - 250px) !important;
    }
  }
  app-solar-planner-google {
    div.planner {
      height: calc(100vh - 250px) !important;
    }
  }
  pc-accordion-wizard-step {
    width: 920px;
  }
}

.icon-bold svg {
  stroke-width: 2.5px !important;
}

.icon-success svg {
  color: colors.$success !important;
}

.icon-warn svg {
  color: colors.$warn !important;
}

.icon-danger svg {
  color: colors.$danger !important;
}

.icon-primary svg {
  color: colors.$primary !important;
}

.accordion-card.accordion {
  position: relative;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: colors.$font-gray;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: colors.$font-gray;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: colors.$font-gray;
}

.select-icon {
  margin-bottom: 0.25rem;
}

.lead-main-drawer {
  padding: 0.75rem 1.5rem;
  z-index: 10;
  top: 0;
  position: fixed;
  width: 100%;
  height: 48px;
  width: calc(100% - 3rem);

  & .select {
    margin-top: 0;
  }
}

.account-assignment-overlay {
  max-width: 200px;
  overflow-x: auto;
}

helion-ui-icon.task-filter-icon {
  &.active {
    .icon {
      color: $c-blue !important;
    }
  }

  &.inactive {
    .icon {
      color: $c-red !important;
    }
  }
}

@mixin coloredOption($color) {
  .option {
    line-height: 32px;
    padding: 6px 0;
    background: $color;
  }
  .option:hover:not(.option-disabled),
  .option:focus:not(.option-disabled) {
    line-height: 32px;
    padding: 6px 0;
    background: lighten($color, 10%);
  }
  .select,
  .select input:disabled {
    line-height: 32px;
    padding: 6px 0;
    color: rgba(0, 0, 0, 0.87);
    background: $color;
  }
}

@mixin coloredLabel($color) {
  &.colored-label {
    padding: 5px;
    margin: 14px 0;
    height: 20px;
    text-align: center;
    color: rgba(0, 0, 0);
    background: $color;
  }
}

.checkedStrong {
  @include coloredOption($c-red);
  @include coloredLabel($c-red);
}

.checkedLight {
  @include coloredOption($c-orange);
  @include coloredLabel($c-orange);
}

.checkedNone {
  @include coloredOption($c-green);
  @include coloredLabel($c-green);
}

.checkedNeutral {
  @include coloredOption($c-gray);
  @include coloredLabel($c-gray);
}

// disabled buttons in helion-ui-menu
helion-ui-menu button:not(.mat-button-toggle-button):disabled {
  color: #bfbfbf !important;

  svg {
    color: #bfbfbf !important;
  }
}

/* lead invoices table */
.lead-invoices-table {
  width: 100%;
  border-collapse: collapse;

  tr:not(.lead-invoices-empty-row) {
    background-color: white;
  }

  .lead-invoices-empty-row,
  .lead-invoices-empty-row-bottom-padding {
    height: 1rem;
  }

  td {
    padding: 0.5rem 1rem;

    & .invoice-status-changed-on {
      font-size: 0.8rem;
      color: #808080;
      text-wrap: nowrap;
    }
  }

  th {
    padding: 1rem;
    text-align: left;
    color: #808080;
    font-weight: 400;
  }

  th.text-right,
  td.text-right {
    text-align: right;
  }

  td.text-bold {
    font-weight: 600;
  }

  .lead-variant-row {
    font-weight: 600;
    ::ng-deep {
      svg {
        stroke-width: 2px;
      }
    }
    td {
      padding: 1rem;
    }

    helion-ui-icon[data-feather='chevron-down'] {
      transition: transform 0.2s ease-in-out;
      transform: rotate(180deg);
    }

    &-collapsed {
      helion-ui-icon[data-feather='chevron-down'] {
        transform: rotate(0deg);
      }
    }
  }

  .lead-invoices-row {
    cursor: pointer;
    &-collapsed {
      visibility: collapse;
    }

    td:first-child {
      padding-left: 2.5rem;
    }
  }

  .lead-invoices-row-actions {
    padding-right: 2.25rem;
  }
}

.lead-detail-invoice-card .lead-invoices-table {
  th {
    color: #000000;
    font-weight: 600;
    padding-top: 0;
  }

  td {
    padding: 1rem;
    border-bottom: 1px dashed #bfbfbf;
  }

  th,
  td {
    &:first-child,
    &:last-child {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
/* lead invoices table */

.replace-icon {
  transform: rotate(-90deg);
}

/* gray-on-hover general rune */
.gray-on-hover {
  &:hover {
    background-color: #f3f4f6;
  }
}

tr.gray-on-hover:hover td {
  background-color: #f3f4f6;
}
/* gray-on-hover general rune */
