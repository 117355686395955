.gu-mirror.article-tree-item,
.dd-article-list .gu-transit.article-tree-item {
  min-height: 47px;
  width: 80vw;
  min-width: 60vw;
  padding-left: 25px;
  background-color: #fff;
  a {
    margin-top: 25px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
    .article-number {
      padding: 10px;
      margin-left: 55px;
      width: 40px;
    }
    .name {
      padding: 10px;
      margin-left: 56px;
    }
    .hyphen {
      display: none;
    }
  }
  button {
    display: none;
  }
}
